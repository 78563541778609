export enum ESearchConditionFields {
  titles = 'titles',
  dateRange = 'dateRange',
  dating = 'dating',
  geographicalReferences = 'geographicalReferences',
  geographicalReferencesID = 'geographicalReferences.id',
  culturalReferences = 'culturalReferences',
  culturalReferencesID = 'culturalReferences.id',
  materialAndTechnique = 'materialAndTechnique',
  materialAndTechniqueID = 'materialAndTechnique.id',
  identNumber = 'identNumber',
  technicalTerm = 'technicalTerm',
  involvedParties = 'involvedParties',
  exhibitions = 'exhibitions',
  involvedPartiesID = 'involvedParties.id',
  iconclasses = 'iconclasses',
  iconography = 'iconography',
  keywords = 'keywords',
}
